import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/layout';

import VerificationConditionsEN from '../../articles/en/VerificationConditions';

const VerificationPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Verification</title>
      <meta name="og:title" content="Verification" />
      <meta name="twitter:title" content="Verification" />
    </Helmet>
    <VerificationConditionsEN />
  </Layout>
);

export default VerificationPage;
